import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const footerItems = [
  {
    label: 'Term of Use',
    url: '/terms-of-use'
  },
  {
    label: 'Terms and Conditions',
    url: '/terms-and-conditions'
  },
  {
    label: 'FAQ',
    url: '/faq'
  }
]

const Footer = () => {
  return (
    <Box sx={{ margin: '10px', marginTop: '100px', width: '100%', backgroundColor: 'white'}}>
      <Box sx={{ paddingLeft: '20px',  paddingRight: '25px' }}>
        {footerItems.map((item) => (
            <Link 
            key={item.label} 
            href={item.url} 
            underline='hover' 
            sx={{ color: 'black', paddingRight: '30px' }}>
            { item.label }
          </Link>
        ))}
      </Box>
    </Box>
  );
}

export default Footer;
