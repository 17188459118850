import Page from 'components/Page';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

const PrintPage = () => {
  return (
    <Page>
      <Box sx={{ marginTop: '200px' }}>
        <Typography sx={{ fontWeight: 500, fontSize: '38px' }} color='secondary'>Print Your Art</Typography>
        <Typography sx={{ maxWidth: '500px', paddingTop: '5px', paddingLeft: '4px' }}>Looking to print your LOVE NFT? We are working with Level Frames to provide a seamless, top-quality experience. All Level Frames prints are printed on gallery-quality paper, beautifully framed, and delivered to your door. Artwork can be printed in a variety of sizes, ranging from 6 in x 6 in up to 26 in x 26 in. If you are looking for a print that is larger than that please contact <a href='mailto:prints@andrepeat.com' target='_blank' rel='noopener noreferrer'>prints@andrepeat.com</a>.</Typography>
        <Grid container rowSpacing={3} columnSpacing={3} sx={{marginTop: '10px', marginBottom: '10px', display: 'flex', alignItems: 'end'}}>
          <Grid item xs={3}>
            <img src='/assets/img/03_PrintYourArt/Love_PrintYourArt_01.png' width='100%'></img>
          </Grid>
          <Grid item xs={3.5}>
            <img src='/assets/img/03_PrintYourArt/Love_PrintYourArt_01.png' width='100%'></img>
          </Grid>
          <Grid item xs={4.5}>
            <img src='/assets/img/03_PrintYourArt/Love_PrintYourArt_01.png' width='100%'></img>
          </Grid>
        </Grid>
        <Typography sx={{ maxWidth: '500px', marginTop: '30px', paddingLeft: '4px' }}>Printing is token-gated, meaning only you will have the right to print your NFT. Hit the link below to order yours.</Typography>
        <Box sx={{ marginTop: '50px', padding: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Button 
            variant='contained'
            color='secondary'
            endIcon={<img src='/assets/img/00_Site/Icon_ExternalLink_White.svg' width='13px'></img>}
            sx={{ paddingTop: '15px', paddingLeft: '25px', paddingRight: '25px',  paddingBottom: '15px', borderRadius: '30px', textTransform: 'none' }}
            onClick={() => {
              window.open("https://www.levelframes.com/web3/collections/love");
            }}
            >
            <Typography sx={{ fontWeight: 500 }}>
              Start Framing
            </Typography>
          </Button>
        </Box>
      </Box>
    </Page>
  )
}

export default PrintPage;
 