import Page from 'components/Page';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const WelcomePage = () => {
  return (
    <Page>
      <Box sx={{ marginTop: '200px' }}>
      <Grid container rowSpacing={3} columnSpacing={3}>
        <Grid item xs={6} md={3}>
          <img src='/assets/img/01_Homepage/Love_Homepage_01.png' width='100%'></img>
        </Grid>
        <Grid item xs={6} md={3}>
          <img src='/assets/img/01_Homepage/Love_Homepage_02.png' width='100%'></img>
        </Grid>
        <Grid item xs={6} md={3}>
          <img src='/assets/img/01_Homepage/Love_Homepage_03.png' width='100%'></img>
        </Grid>
        <Grid item xs={6} md={3}>
          <img src='/assets/img/01_Homepage/Love_Homepage_04.png' width='100%'></img>
        </Grid>
      </Grid>
      <Box sx={{ marginTop: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Typography sx={{ maxWidth: '600px', fontSize: '20px' }}>A collaboration between the ATP Tour, Art Blocks Engine, and Martin Grasser, LOVE is a collection of unique digital artworks that uses in-match sports data to celebrate impactful moments from the 2022 Nitto ATP Finals in Turin, Italy. The artworks will go on sale December 6 at 12pm EST in a Dutch auction starting at 3 ETH and resting at .3 ETH.</Typography>
      </Box>
      </Box>
    </Page>
  )
}

export default WelcomePage;
 