import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import { generatorUrl, coreContractAddress, mediaUrl } from 'config';
import { useInterval } from "utils/useInterval"
import axios from 'axios';

interface Props {
  tokenId: string;
  width: number;
  aspectRatio?: number;
}

const PollingGenerator = ({
  tokenId,
  width,
  aspectRatio=1
}: Props) => {
  const height = width / aspectRatio;
  const endpoint = `${generatorUrl}/${coreContractAddress?.toLowerCase()}/${tokenId}`
  const [indexed, setIndexed] = useState(false);

  useInterval(() => {
    if (!indexed) {
      const checkIndexed = async () => {
        try {
          const { data } = await axios.get(endpoint);
        } catch (error) {
          setIndexed(false);
        } finally {
          setIndexed(true);
        }        
      }
      checkIndexed()
    }
  }, 1000 * 5);
  
  return (
    <Card>
      {
        indexed && ( 
          <iframe
            title={tokenId}
            src={endpoint}
            width={String(width) + 'px'}
            height={String(height) + 'px'}
            frameBorder='0'
            style={{ marginBottom: '-7px' }}
          />
        )
      }
      {
        !indexed && (
          <Box sx={{ width: String(width) + 'px', height: '300px', padding: '100px' }}>
            <Typography variant='h6' color='secondary' p='10px'>
              Waiting for indexing...
            </Typography>
            <LinearProgress color='secondary' />
          </Box>
        )
      }
    </Card>
  )
}

export default PollingGenerator;
