import { useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { GenArt721CoreV2__factory } from 'contracts';
import { useWeb3React } from '@web3-react/core';
import { coreContractAddress } from 'config';

export default function useProjectPrice(
  projectId: string,
): {
  projectPaused: boolean,
  loadingProjectPaused: boolean,
  errorProjectPaused: boolean,
} {
  const { account, provider } = useWeb3React();
  const signer = provider!.getSigner(account);
  const address = coreContractAddress ? coreContractAddress : ""
  const abCoreContract = GenArt721CoreV2__factory.connect(address, signer);
  const [projectPaused, setProjectPaused] = useState(true);
  const [loadingProjectPaused, setLoadingProjectPaused] = useState(true);
  const [errorProjectPaused, setErrorProjectPaused] = useState(false);

  const fetchProjectPaused = async () => {
    try {
      const p = await abCoreContract.projectScriptInfo(BigNumber.from(projectId));
      setProjectPaused(p.paused);
    } catch(error) {
      setErrorProjectPaused(true);
    } finally {
      setLoadingProjectPaused(false);
    }
  }
  
  useState(() => {
    fetchProjectPaused();
  });

  return {
    projectPaused,
    loadingProjectPaused,
    errorProjectPaused
  };
}
