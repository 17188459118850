import Page from 'components/Page';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

const TermsAndConditionsPages = () => {
  return (
    <Page>
       <Container sx={{padding:'0 1rem'}}>
      <Box sx={{marginTop: '12rem', maxWidth: '575px' }}>
      <Typography sx={{ fontWeight: 500 }} variant='h3' color='secondary'>Terms of Use</Typography>
      <Typography variant='body1' sx={{ padding: '25px 0' }}><strong>Last Modified</strong>: November 17, 2022</Typography>
      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}>Acceptance of the Terms of Use</Typography>
        
      <Typography sx={{ paddingTop: '25px' }}>These terms of use are entered into by and between you and AndRepeat, Inc. (“<strong>Company</strong>,” “<strong>we</strong>,” or “<strong>us</strong>”), the operator of this website (the “<strong>Website</strong>”). The following terms and conditions (“<strong>Terms of Use</strong>”) govern your access to and use of this Website, including any content, functionality, and services offered on or through it. </Typography>
      <Typography variant='body1'>Please read these Terms of Use carefully before you start to use the Website.<strong>By using the Website you accept and agree to be bound and abide by these Terms of Use.</strong>  If you do not want to agree to these Terms of Use, you must not access or use the Website. </Typography>
      <Typography variant='body1'><strong>Notwithstanding anything to the contrary in these Terms of Use, all purchases of NFTs through this Website are governed by the <a href='/terms-and-conditions'><u>ATP Tour “LOVE” Digital Art NFT Collection Terms & Conditions</u></a> (“NFT Terms”) rather than these Terms of Use. Please review those NFT Terms prior to purchasing any NFT through this Website.</strong></Typography>
      <Typography variant='body1'>This Website is offered and available to users who are 18 years of age or older. By using this Website, you represent and warrant that you are of legal age to form a binding contract with the Company. If you do not meet this requirement, you must not access or use the Website. You affirm that if you are using the Website on behalf of an organization or company, you have the legal authority to bind any such organization or company to these Terms of Use.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Changes to the Terms of Use</Typography>
      <Typography variant='body1'>We may revise and update these Terms of Use from time to time in our sole discretion. All changes are effective immediately when we post them, and apply to all access to and use of the Website thereafter. However, any changes to the dispute resolution provisions set out in the Governing Law and Jurisdiction section of these Terms of Use will not apply to any disputes for which the parties have actual notice on or before the date the change is posted on the Website. </Typography>
      <Typography variant='body1'>Your continued use of the Website following the posting of revised Terms of Use means that you accept and agree to the changes. You are expected to check this page from time to time so you are aware of any changes, as they are binding on you. </Typography>
        
      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Accessing the Website </Typography>
      <Typography variant='body1'>We reserve the right to withdraw or amend this Website, and any service or material we provide on the Website, in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website is unavailable at any time or for any period. From time to time, we may restrict access to some parts of the Website, or the entire Website.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Intellectual Property Rights</Typography>
      <Typography variant='body1'>The Website and its entire contents, features, and functionality (including, but not limited to, all information, software, text, displays, images, non-fungible tokens, video and audio, and the design, selection, and arrangement thereof) are owned by ATP Tour, Inc. (“<strong>ATP</strong>”) or the Company, their respective licensors, or other providers of such material and are protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</Typography>
      <Typography variant='body1'>These Terms of Use permit you to use the Website for your personal, non-commercial use only. You must not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our Website, except as follows:</Typography>
      <ul>
        <li>Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.</li>
        <li>You may store files that are automatically cached by your Web browser for display enhancement purposes.</li>
      </ul>
      <Typography variant='body1'>You must not:</Typography>
      <ul>
        <li>Modify copies of any materials from this site.</li>
        <li>Use any illustrations, photographs, video or audio sequences, or any graphics separately from the accompanying text.</li>
        <li>Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.</li>
      </ul>
      <Typography variant='body1'>You must not access or use for any commercial purposes any part of the Website or any services or materials available through the Website. </Typography>
      <Typography variant='body1'>If you print, copy, modify, download, or otherwise use or provide any other person with access to any part of the Website in breach of the Terms of Use, your right to use the Website will stop immediately and you must, at our option, return or destroy any copies of the materials you have made. No right, title, or interest in or to the Website or any content on the Website is transferred to you, and all rights not expressly granted are reserved by the Company. Any use of the Website not expressly permitted by these Terms of Use is a breach of these Terms of Use and may violate copyright, trademark, and other laws.</Typography>
      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Trademarks </Typography>
      <Typography variant='body1'>The Company’s and ATP’s respective names, logos, product and service names, designs, and slogans are trademarks of the Company or ATP or their respective affiliates or licensors. You must not use such marks without the prior written permission of the Company or ATP. All other names, logos, product and service names, designs, and slogans on this Website are the trademarks of their respective owners.</Typography>
    
      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Use of the Website</Typography>
      <Typography variant='body1'>You (i) must not mint any NFT or use the Website for any purpose that violates any law, rule, or regulation, or that may subject the Company, ATP and/or any of their respective affiliates or commercial partners to public disrepute, contempt, scandal or ridicule; and (ii) must not, whether directly or indirectly: (a) engage in or attempt to engage in any act or omission, employ any device, scheme or artifice to defraud, or otherwise materially mislead, the Company or ATP; (b) engage or attempt to engage in or assist in any hack of or attack on the Website, including any “sybil attack,” “DoS attack” or “griefing attack” or theft of NFTs, or funds, or upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another’s computer or property or interfere with the Website; (c) provide, upload, or otherwise make available, any materials that contain images, photographs, software or other material that infringes or is in a manner infringing the copyright, trademark, patent, trade secret, privacy, publicity or other intellectual property rights of others or that is libelous, defamatory, profane, obscene, pornographic, indecent, unlawful or otherwise objectionable; or (d) defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy and publicity) of another user of the Website.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Reliance on Information Posted</Typography>
      <Typography variant='body1'>The information presented on or through the Website is made available solely for general information purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you place on such information is strictly at your own risk. We disclaim all liability and responsibility arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone who may be informed of any of its contents.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Changes to the Website </Typography>
      <Typography variant='body1'>We may update the content on this Website from time to time, but its content is not necessarily complete or up-to-date. Any of the material on the Website may be out of date at any given time, and we are under no obligation to update such material. </Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Links from the Website </Typography>
      <Typography variant='body1'>If the Website contains links to other sites and resources provided by third parties, these links are provided for your convenience only. We have no control over the contents of those sites or resources, and accept no responsibility for them or for any loss or damage that may arise from your use of them. If you decide to access any of the third-party websites linked to this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such websites.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Disclaimer of Warranties </Typography>
      <Typography variant='body1'>You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Website will be free of viruses or other destructive code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to our site for any reconstruction of any lost data. </Typography>
      <Typography variant='body1'>TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.</Typography>
      <Typography variant='body1'>YOUR USE OF THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE WEBSITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. </Typography>
      <Typography variant='body1'>TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. </Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Limitation on Liability </Typography>
      <Typography variant='body1'>TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY OR ATP, OR THEIR RESPECTIVE AFFILIATES, OR THEIR RESPECTIVE LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE. </Typography>
      <Typography variant='body1'>THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. YOU ACKNOWLEDGE AND AGREE THAT WE HAVE MADE THE WEBSITE AVAILABLE TO YOU AND ENTERED INTO THESE TERMS OF USE IN RELIANCE UPON THE REPRESENTATIONS AND WARRANTIES, DISCLAIMERS AND LIMITATIONS OF LIABILITY SET FORTH HEREIN, WHICH REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN US AND YOU AND FORM AN ESSENTIAL BASIS OF THE BARGAIN BETWEEN US AND YOU. WE WOULD NOT BE ABLE TO PROVIDE THE WEBSITE TO YOU WITHOUT THESE LIMITATIONS.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em',fontWeight: 500 }}> Indemnification </Typography>
      <Typography variant='body1'>You agree to defend, indemnify, and hold harmless the Company and ATP, and their respective affiliates, licensors, and service providers, and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Website.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em',  fontWeight: 500 }}> Governing Law and Jurisdiction </Typography>
      <Typography variant='body1'>All matters relating to the Website and these Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including non-contractual disputes or claims), shall be governed by and construed in accordance with the internal laws of the State of New York without giving effect to any choice or conflict of law provision or rule (whether of the State of New York or any other jurisdiction).</Typography>
      <Typography variant='body1'>Any legal suit, action, or proceeding arising out of, or related to, these Terms of Use or the Website shall be instituted exclusively in the federal courts of the United States or the courts of the State of New York, in each case located in New York, NY, although we retain the right to bring any suit, action, or proceeding against you for breach of these Terms of Use in your country of residence or any other relevant country. You waive any and all objections to the exercise of jurisdiction over you by such courts and to venue in such courts.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Limitation on Time to File Claims</Typography>
      <Typography variant='body1'>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Waiver and Severability </Typography>
      <Typography variant='body1'>No waiver by the Company of any term or condition set out in these Terms of Use shall be deemed a further or continuing waiver of such term or condition or a waiver of any other term or condition, and any failure of the Company to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</Typography>
      <Typography variant='body1'>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal, or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect. </Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Entire Agreement </Typography>
      <Typography variant='body1'>These Terms of Use constitute the sole and entire agreement between you and the Company regarding the Website and supersede all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, regarding the Website. </Typography>

      <Typography variant='h5' color='secondary' sx={{ paddingTop: '25px', paddingBottom: '0.8em', fontWeight: 500 }}> Third-Party Beneficiaries </Typography>
      <Typography variant='body1'>ATP is an expressly intended third-party beneficiary of these Terms of Use.</Typography>

      </Box>
      </Container>
    </Page>
  )
}

export default TermsAndConditionsPages;
 