import { Container, Box } from '@mui/material';
import Header from 'components/Header';
import Footer from 'components/Footer';

interface Props {
  children: React.ReactNode;
}

const Page = ({ children }: Props) => {
  return (
    <Box sx={{
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
      justifyContent: 'space-between'
      }}
    >
      <Header />
      <main>
        <Container sx={{
          paddingTop: 3
        }}>
          { children }
        </Container>
      </main>
      <Footer />
    </Box>
  );
}

export default Page;
