import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import WelcomePage from 'components/pages/WelcomePage';
import CollectionPage from 'components/pages/CollectionPage';
import ProjectsPage from 'components/pages/ProjectsPage';
import ProjectPage from 'components/pages/ProjectPage';
import TokenPage from 'components/pages/TokenPage';
import AboutPage from 'components/pages/AboutPage';
import PrintPage from 'components/pages/PrintPage';
import TermsofUsePage from 'components/pages/TermsofUsePage';
import TermsAndConditionsPage from 'components/pages/TermsAndConditionsPage';
import QuestionsPage from 'components/pages/QuestionsPage';
import AppProviders from 'components/AppProviders';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <AppProviders>
      <Router>
        <Routes>
          <Route index element={<WelcomePage />} />
          <Route path='collection' element={<CollectionPage />} />
          <Route path='about' element={<AboutPage />} />
          <Route path='print' element={<PrintPage />} />
          <Route path='terms-of-use' element={<TermsofUsePage />} />
          <Route path='terms-and-conditions' element={<TermsAndConditionsPage />} />
          <Route path='faq' element={<QuestionsPage />} />
          <Route path='projects' element={<ProjectsPage />} />
          <Route path='project/:projectId' element={<ProjectPage />} />
          <Route path='token/:id' element={<TokenPage />} />
        </Routes>
      </Router>
      <ToastContainer
        autoClose={10000}
        position='bottom-right'
        theme='light'
        newestOnTop
        pauseOnHover
        pauseOnFocusLoss
      />
    </AppProviders>
  );
}

export default App;
