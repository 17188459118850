import { useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { GenArt721Minter__factory } from 'contracts';
import { useWeb3React } from '@web3-react/core';
import { mintContractAddress } from 'config';

export default function useProjectMaxInvoked(
  projectId: string,
): {
  maxInvoked: boolean,
  loadingMaxInvoked: boolean,
  errorMaxInvoked: boolean,
} {
  const { account, provider } = useWeb3React();
  const signer = provider!.getSigner(account);
  const address = mintContractAddress ? mintContractAddress : ""
  const abMinterContract = GenArt721Minter__factory.connect(address, signer);

  const [maxInvoked, setMaxInvoked] = useState(true);
  const [loadingMaxInvoked, setLoadingMaxInvoked] = useState(true);
  const [errorMaxInvoked, setErrorMaxInvoked] = useState(false);

  const checkMaxHasBeenInvoked = async () => {
    try {
      const m = await abMinterContract.projectMaxHasBeenInvoked(BigNumber.from(projectId));
      setMaxInvoked(m);
    } catch(error) {
      setErrorMaxInvoked(true);
    } finally {
      setLoadingMaxInvoked(false);
    }
  }
  
  useState(() => {
    checkMaxHasBeenInvoked();
  });

  return {
    maxInvoked,
    loadingMaxInvoked,
    errorMaxInvoked
  };
}
