import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Link from '@mui/material/Link';
import ConnectWallet from 'components/ConnectWallet';
import Countdown from 'components/Countdown';

const navItems = [
  {
    label: 'The Collection',
    url: '/collection',
    external: false,
    enabled: true
  },
  {
    label: 'Mint',
    url: '/project/0',
    external: false,
    enabled: true
  },
  {
    label: 'Print Your Art',
    url: '/print',
    external: false,
    enabled: true
  },
  {
    label: 'About Us',
    url: '/about',
    external: false,
    enabled: true
  },
  {
    label: 'Nitto ATP Finals',
    url: 'https://www.nittoatpfinals.com/',
    external: true,
    enabled: true
  }
]

const DRAWER_WIDTH = 240;

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List dense>
        {navItems.map((item) => (
          <ListItem sx={{
            '&:hover': {
              backgroundColor: '#f5f5f5'
            },
          }} key={item.label} disablePadding>
            { !item.external ? 
              <ListItemButton component={Link} href={item.url} sx={{ textAlign: 'left', pointerEvents: item.enabled ? 'auto' : 'none'}}>
                <ListItemText primary={item.label} primaryTypographyProps={{ fontSize: '18px', color: item.enabled ? 'black' : 'lightgrey' }}/>
              </ListItemButton>
              :
              <Box sx={{ paddingLeft: '15px', paddingTop: '10px', paddingBottom: '10px', fontSize: '18px'}}>
                <a className='mobile-link' href={item.url} target='_blank' rel='noopener noreferrer'>{item.label} <img src='/assets/img/00_Site/Icon_ExternalLink_Black.svg' width='13px'></img></a>
              </Box>
            }
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Box sx={{ width: '100%', display: 'flex', backgroundColor: 'black', position: 'fixed' }}>
      <AppBar component='nav' position='static' elevation={1} sx={{ backgroundColor: 'black', boxShadow: 0 }}>
      <Box sx={{ background: '#2458F6' }}>
        <Countdown />
      </Box>
        <Toolbar sx={{ width: '100%', display: 'flex', margin: 'auto', justifyContent: 'space-between', backgroundColor: 'white' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', backgroundColor: 'white' }}>
            <IconButton
              color='default'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <Box sx={{paddingRight: '50px'}}>
              <Link href='/' underline='hover' sx={{ color: 'black'}}>
                <img src='/assets/img/00_Site/Logo_Love_Color.svg' height='46px'></img>
              </Link>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {navItems.map((item) => (
              !item.external ?
              <Link 
                key={item.label} 
                href={item.url} 
                underline='hover'
                sx={{ color: item.enabled ? 'black' : 'lightgrey', paddingRight: '25px', pointerEvents: item.enabled ? 'auto' : 'none' }}>
                {item.label}
              </Link> 
              : <a href={item.url} target='_blank' rel='noopener noreferrer'>{item.label} <img src='/assets/img/00_Site/Icon_ExternalLink_Black.svg' width='13px'></img></a>
              ))}
            </Box>
          </Box>
          <ConnectWallet />
        </Toolbar>
      </AppBar>
      <Box component='nav'>
        <Drawer
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: DRAWER_WIDTH,
            },
          }}
          >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Header;
