import Page from 'components/Page';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';

const CollectionPage = () => {
  return (
    <Page>
      <Box sx={{ marginTop: '200px' }}>
        <Typography sx={{ fontWeight: 500, fontSize: '38px' }} color='secondary'>The Collection</Typography>
        <Typography sx={{ maxWidth: '500px', paddingTop: '5px', paddingLeft: '4px' }}>Thirty color palettes, 114 camera angles, and various zoom levels work in collaboration with the lines on the court and a bright yellow ball to create endless possibilities. The outputs serve as an artistic record of eight days of incredible tennis in competition.</Typography>
        <Grid container rowSpacing={2} columnSpacing={2} sx={{marginTop: '25px'}}>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_01.png' width='100%'></img>
          </Grid>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_02.png' width='100%'></img>
          </Grid>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_03.png' width='100%'></img>
          </Grid>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_04.png' width='100%'></img>
          </Grid>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_05.png' width='100%'></img>
          </Grid>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_06.png' width='100%'></img>
          </Grid>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_07.png' width='100%'></img>
          </Grid>
          <Grid item xs={3}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_08.png' width='100%'></img>
          </Grid>
        </Grid>
        <Typography sx={{ paddingTop: '120px', fontWeight: 500, fontSize: '38px' }} color='secondary'>The Idea</Typography>
        <Typography sx={{ maxWidth: '500px', paddingTop: '5px', paddingLeft: '4px' }}>Our algorithm is built on the idea that there is beauty in the very rules of the game. And one of those rules is the court, a highly regulated environment that is essentially a handful of rectangles and lines. Add a ball—a circle, really—and the formula is complete. What we have is not just a record of incredible skill and sportsmanship, but art, as it were, ready-made.</Typography>
        <Typography sx={{ maxWidth: '500px', paddingTop: '5px', paddingLeft: '4px' }}>Wherever the ball lands, we mark it.</Typography>
        <Grid container rowSpacing={1} columnSpacing={2} sx={{marginTop: '25px'}}>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_09.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_10.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_11.png' width='100%'></img>
          </Grid>
        </Grid>
        <Typography sx={{ maxWidth: '500px', paddingTop: '100px', paddingLeft: '4px' }}>Underneath each ball is a shadow-like ellipse that indicates the direction and speed of each shot.</Typography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_12.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_13.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_14.png' width='100%'></img>
          </Grid>
        </Grid>
        <Typography sx={{ maxWidth: '500px', paddingTop: '100px', paddingLeft: '4px' }}>We add color...</Typography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_15.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_16.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_17.png' width='100%'></img>
          </Grid>
        </Grid>
        <Typography sx={{ maxWidth: '500px', paddingTop: '100px', paddingLeft: '4px' }}>... ensuring that each composition reveals at least one line and two fields of color.</Typography>
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_18.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_19.png' width='100%'></img>
          </Grid>
          <Grid item xs={4}>
            <img src='/assets/img/02_TheCollection/Love_TheCollection_20.png' width='100%'></img>
          </Grid>
        </Grid>
        <Typography sx={{ maxWidth: '500px', paddingTop: '40px', paddingLeft: '4px', paddingBottom: '10px' }}>Then, with data generated by the ATP, we curate 300 of the most impactful moments from the 2022 Nitto ATP Finals, from the fastest serve to the championship winning shot.</Typography>
        <CardMedia sx={{ maxWidth: '500px', paddingLeft: '4px' }}
          component='video'
          autoPlay
          loop
          src='/assets/vid/Love_Nitto_TheCollection.mp4'
        />
        <Typography sx={{ paddingTop: '100px',  fontWeight: 500, fontSize: '38px' }} color='secondary'>The Data</Typography>
        <Typography sx={{ maxWidth: '500px', paddingTop: '5px', paddingLeft: '4px' }}>All data used in LOVE has been provided by Tennis Data Innovations (TDI), with additional insights provided by TennisViz. TDI data is generated by ten high-definition cameras placed around the court that capture ball location, velocity, and acceleration every 0.02 seconds. It is this data that powers electronic line-calling and broadcast graphics, and allows us to recreate special moments as digital art.</Typography>
      </Box>
    </Page>
  )
}

export default CollectionPage;
 