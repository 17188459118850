import Page from 'components/Page';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const AboutPage = () => {
  return (
    <Page>
      <Box sx={{ marginTop: '200px', marginLeft: '50px'}}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <img src='/assets/img/04_AboutUs/MartinGrasser_Squares_81.png' height='220px'></img>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontWeight: 500, fontSize: '38px' }} color='secondary'>Martin Grasser</Typography>
            <Typography sx={{ paddingTop: '5px', paddingLeft: '4px', maxWidth: '500px' }}>Martin Grasser is an artist and designer based in the Bay Area. He produces work using an iterative process, isolating core concepts and expanding upon them through repetition. Martin’s most recognized work to date is the Twitter logo, which has ingrained itself as one of our most iconic symbols; but, it is his interpretation of everyday letterforms that has led to his most profound artistic endeavours. What many see as plain text, Martin sees as art. He explores the transformation of typographic characters in everyday magazines, newspapers and books into a universe of vibrant abstraction. While Martin's practice is rooted in language, the world that he creates encompasses much more, art is present everywhere.</Typography>
          </Grid>
        </Grid>
        <Box sx={{ margin: '100px'}}/>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <img src='/assets/img/04_AboutUs/Logo_ATP_Stacked_Black.svg' height='90px'></img>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontWeight: 500, fontSize: '38px' }} color='secondary'>ATP Tour</Typography>
            <Typography sx={{ paddingTop: '5px', maxWidth: '500px' }}>As a global governing body of men’s professional tennis, the ATP’s mission is to serve tennis. We entertain a billion global fans, showcase the world’s greatest players at the most prestigious tournaments, and inspire the next generation of fans and players. From the United Cup in Australia, to Europe, the Americas and Asia, the stars of the game battle for titles and Pepperstone ATP Rankings points at ATP Masters 1000, 500 and 250 events, and Grand Slams. All roads lead towards the Nitto ATP Finals, the prestigious season finale held in Turin, Italy. Featuring only the world’s top 8 qualified singles players and doubles teams, the tournament also sees the official crowning of the year-end ATP World No. 1, presented by Pepperstone, the ultimate achievement in tennis. For more information, please visit <a href='https://ATPTour.com' target='_blank' rel='noopener noreferrer'>www.ATPTour.com</a>.</Typography>
          </Grid>
        </Grid>
        <Box sx={{ margin: '100px'}}/>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <img src='/assets/img/04_AboutUs/TDI Logo_Portrait_RGB_Black.png' height='50px'></img>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontWeight: 500, fontSize: '38px' }} color='secondary'>Tennis Data Innovations</Typography>
            <Typography sx={{ paddingTop: '5px', paddingLeft: '4px', maxWidth: '500px' }}>TDI powers the innovation, development, marketing and distribution of future data products in tennis. Established in 2020 as an independent joint venture between ATP and ATP Media, TDI manages and commercialises data across a variety of global markets, including betting, media and performance. In parallel, TDI manages betting live streaming from more than 14,500 tennis matches across the ATP Tour and ATP Challenger Tour each season. For more information, please visit <a href='https://tennisdata.com' target='_blank' rel='noopener noreferrer'>www.tennisdata.com</a>.</Typography>
          </Grid>
        </Grid>
        <Box sx={{ margin: '100px'}}/>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <img src='/assets/img/04_AboutUs/Logo_ArtBlocksEngine_Color.png' height='65px'></img>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography sx={{ fontWeight: 500, fontSize: '38px' }} color='secondary'>Art Blocks Engine</Typography>
            <Typography sx={{ paddingTop: '5px', paddingLeft: '4px', maxWidth: '500px' }}>Art Blocks Engine is focused on sharing the delight of generative systems through meaningful partnerships with brands and creators. Our Engine tech handles the Web 3 infrastructure so teams can focus on storytelling, community, and culture.</Typography>
          </Grid>
        </Grid>
      </Box>
    </Page>
  )
}

export default AboutPage;
 