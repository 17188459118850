import moment from 'moment-timezone';
import Typography from '@mui/material/Typography';

const Countdown = () => {
  return (
    <Typography sx={{ padding: '10px', color: 'white' }}>
      Collection opens <b>{moment.tz("2022-12-06 12:00", "America/New_York").fromNow()}</b>
    </Typography>
  )
};

export default Countdown;