import { useCallback, useState } from 'react';
import { ethers, utils, BigNumber } from 'ethers';
import moment from 'moment';
import { useWeb3React } from '@web3-react/core';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { Project } from 'utils/types';
import { CHAINS } from 'utils/chains';
import { notifyTx } from 'utils/notifications';
import { GenArt721Minter__factory } from 'contracts';
import useProjectPrice from 'hooks/useProjectPrice';
import useProjectPaused from 'hooks/useProjectPaused';
import useProjectMaxInvoked from 'hooks/useProjectMaxInvoked';
import MintSuccessDialog from './MintSuccessDialog';
import { expectedChainId, mintContractAddress } from 'config';

interface Props {
  project: Project;
}

const PurchaseProject = ({ project }:Props) => {
  const { priceWei, loading, error } = useProjectPrice(project.projectId.toString());
  const { projectPaused, loadingProjectPaused, errorProjectPaused } = useProjectPaused(project.projectId.toString());
  const { maxInvoked, loadingMaxInvoked, errorMaxInvoked } = useProjectMaxInvoked(project.projectId.toString());
  const { chainId, isActive, account, connector, provider } = useWeb3React();
  const startTime = project?.minterConfiguration?.startTime && moment.unix(parseInt(project.minterConfiguration?.startTime?.toString()));
  const [pending, setPending] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [mintedTokenId, setMintedTokenId] = useState<number | null>(null);
  const priceEther = priceWei ? utils.formatEther(priceWei.toString()) : "?";
  const isArtist = account?.toLowerCase() === project.artistAddress?.toLowerCase()
  const isMintable =  (project.active && !projectPaused) || isArtist

  const connect = useCallback(() => {
    if (connector?.activate) {
      connector.activate();
    }
  }, [connector]);

  const mintAction = async () => {
    if (provider && mintContractAddress) {
      const signer = provider.getSigner(account);
      const abMinterContract = GenArt721Minter__factory.connect(mintContractAddress, signer);
      const gasLimit = await abMinterContract.estimateGas.purchase(BigNumber.from(project.projectId), {
        value: priceWei,
      });

      return abMinterContract.purchase(BigNumber.from(project.projectId), {
        value: priceWei,
        gasLimit,
      });
    }
    return Promise.reject(new Error('Mint contract or provider not properly configured'));
  }

  const mint = () => {
    if (!provider || !mintContractAddress) {
      return; 
    }
    notifyTx({
      method: mintAction,
      chainId: expectedChainId,
      success: 'Your token has been minted!',
      error: 'An error occured while trying to mint.',
      onSuccess: (receipt:any) => {
        const tokenId = parseInt(receipt?.events[0]?.topics[3], 16);
        setMintedTokenId(tokenId);
        setPending(false);
        setSuccessOpen(true);
      },
      onSubmitted: () => setPending(true),
      onError: () => setPending(false),
    }); 
  }

  if (!project) {
    return null;
  }

  if (!isMintable) {
    return (
      <Button
        variant='contained'
        color='primary'
        disabled
      >
        Purchases paused
      </Button>
    );
  }

  if (startTime && startTime.isAfter()) {
    return <Alert severity='info'>Upcoming</Alert>
  }

  if (!project.active) {
    return <Alert severity='info'>Project is not active</Alert>
  }

  if (!project.active) {
    return <Alert severity='info'>Project is not active</Alert>
  }

  if (project.complete) {
    return <Alert severity='info'>Sold out</Alert>
  }

  if (!isActive) {
    return (
      <Button
      variant='contained'
      color='secondary'
      sx={{ paddingTop: '15px', paddingLeft: '25px', paddingRight: '25px',  paddingBottom: '15px', borderRadius: '30px', textTransform: 'none' }}
      onClick={connect}
      >
        <Typography sx={{ fontWeight: 500 }}>
          Connect to purchase
        </Typography>
      </Button>
    );
  }

  if (chainId !== expectedChainId) {
    return (
      <Alert severity='warning'>
        Switch to { CHAINS[expectedChainId]?.name } to purchase
      </Alert>
    );
  }
  
  const Mint = () => (
    <Button
    variant='contained'
    color='secondary'
    sx={{ paddingTop: '15px', paddingLeft: '25px', paddingRight: '25px',  paddingBottom: '15px', borderRadius: '30px', textTransform: 'none' }}
    onClick={mint}
    >
      <Typography sx={{ fontWeight: 500 }}>
        Purchase for { priceEther } { project.currencySymbol }
      </Typography>
    </Button>
  );

  return (
    <>
      {
        pending ? (
          <LoadingButton
            loading
            variant='contained'
            color='secondary'
            sx={{ paddingTop: '15px', paddingLeft: '25px', paddingRight: '25px',  paddingBottom: '15px', borderRadius: '30px', textTransform: 'none' }}
          >
          <Typography sx={{ fontWeight: 500 }}>
            Purchasing for { priceEther } { project.currencySymbol }
          </Typography>
          </LoadingButton>
        ) : maxInvoked ? <></> : <Mint />
      }
      <MintSuccessDialog
        mintedTokenId={String(mintedTokenId)}
        open={successOpen}
        handleClose={() => {
          setSuccessOpen(false)
        }}
      />
    </>
  )
}

export default PurchaseProject;
