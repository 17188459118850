import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import useProject from 'hooks/useProject';
import { useWindowSize } from 'hooks/useWindowSize';
import useTheme from '@mui/material/styles/useTheme';
import TokenPreview from './TokenPreview';
import ProjectStats from './ProjectStats';
import Loading from './Loading';
import TokenList from './TokenList';
import PurchaseProject from './PurchaseProject';
import { tokensPerPage } from 'config';
import { parseScriptType, parseAspectRatio } from 'utils/scriptJSON';
import { OrderDirection } from 'utils/types';
import Collapsible from './Collapsible';
import { useWeb3React } from '@web3-react/core';

interface Props {
  id: string;
}

interface TitleProps {
  children: any;
}

const Title = ({ children }: TitleProps) => (
  <Typography
    fontSize='12px'
    textTransform='uppercase'
    mb={2}
  >
    { children }
  </Typography>
);

const ProjectDetails = ({ id }: Props) => {
  const { chainId, isActive, account, connector, provider } = useWeb3React();
  const isConnected = account !== undefined
  const { loading, error, data } = useProject(id);
  const [currentPage, setCurrentPage] = useState(0);
  const [orderDirection, setOrderDirection] = useState(OrderDirection.ASC);
  const size = useWindowSize();
  const theme = useTheme();

  if (data?.project === null) {
    return <Alert severity='error'>Project not found</Alert>
  }

  if (loading) {
    return <Loading />
  }

  if (error) {
    return (
      <Alert severity='error'>
        Error loading project
      </Alert>
    )
  }

  const project = data?.project;
  const token = project?.tokens[0];
  const width = size.width > theme.breakpoints.values.md
    ? (Math.min(size.width, 1200)- 48)*0.666666
      : size.width > theme.breakpoints.values.sm
        ? size.width - 48
        : size.width - 32;

  const {
    name,
    description,
    artistName,
    artistAddress,
    website,
    license,
    paused,
    complete,
    invocations,
    maxInvocations,
    scriptJSON,
  } = project;
  
  return project && (
    <Box sx={{ marginTop: '150px' }}>
      <Grid spacing={2} container>
        {
          project.tokens?.length > 0 && (
            <Grid item md={8}>
              <TokenPreview
                id={token.id}
                tokenId={token.tokenId}
                invocation={token.invocation}
                aspectRatio={parseAspectRatio(scriptJSON)}
                width={width}
                showImageLink
                showLiveViewLink
              />
            </Grid>
          )
        }
        
        <Grid item md={4} xs={12} sm={12}>
          <Box sx={{ width: '100%', paddingLeft: [0, 0, 2]}}>
            <ProjectStats
              paused={paused}
              complete={complete}
              startTime={project?.minterConfiguration?.startTime}
            />
            
            <Typography variant='h4' mt={3}>
              { name } 
            </Typography>

            <Typography variant='h6' mb={2}>
              { artistName }
            </Typography>

            <Divider sx={{ display: ['none', 'block', 'none'], marginBottom: 2 }} />

            <Box sx={{ fontWeight: 'bold' }}>
              { invocations } / { maxInvocations } minted
            </Box>

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 3,
            }}>
              <LinearProgress
                sx={{ width: 'calc(100% - 48px)' }}
                value={(invocations/maxInvocations)*100}
                color='secondary'
                variant='determinate'
              />
              <Box sx={{ fontSize: 12 }}>
                { Math.floor((invocations/maxInvocations)*100) } %
              </Box>
            </Box>

            { isConnected && <PurchaseProject project={project} />  }

            <Box paddingTop={5} paddingBottom={3}>
              <Collapsible content={description} />
            </Box>

            <Divider />

            <Typography paddingTop={3}>
              The artworks will be auctioned on this website on December 6 at 12pm EST. The auction format will be a Dutch auction where the price decreases over time. The starting price of 3 ETH will drop every three minutes in the following tiers until it reaches a resting price of .3 ETH:
            </Typography>

            <Typography paddingTop={3}>
              3 → 2.5 → 2.0 → 1.75 → 1.5 → 1.25 → 1.00 → .75 → .5 → .3
            </Typography>

            <Typography paddingTop={3} paddingBottom={3}>
              If the price reaches .3 ETH, it will remain there until all artworks are sold. Please note the first 15 artworks have been minted in advance for the ATP, and one for the artist (mint #0).
            </Typography>

            <Divider />

            <Grid item md={7} sm={12} xs={12}>
              <Box sx={{ display: 'flex', marginTop: 4 }}>
                <Box mr={6}>
                  <Title>
                    License
                  </Title>
                  <Typography>
                    { license }
                  </Typography>
                </Box>

                <Box>
                  <Title>
                    Library
                  </Title>
                  <Typography>
                    { parseScriptType(scriptJSON) }
                  </Typography>
                </Box>
              </Box>
            </Grid>

          </Box>
        </Grid>
      </Grid>

      <Divider sx={{paddingTop: 3}} />

      <Box px={1}>
        <Box mt={4} mb={4} sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant='h4'>{ invocations} Item{ Number(invocations) === 1 ? '' : 's' }</Typography>

          
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box>
              <FormControl fullWidth>
                <InputLabel variant='standard' htmlFor='uncontrolled-native'>
                  Sort
                </InputLabel>
                <NativeSelect
                  value={orderDirection}
                  sx={{ fontSize: '14px' }}
                  onChange={(e) => {
                    setCurrentPage(0);
                    setOrderDirection(e.target.value as OrderDirection)
                  }}
                >
                  <option value={OrderDirection.DESC}>Latest</option>
                  <option value={OrderDirection.ASC}>Earliest</option>
                </NativeSelect>
              </FormControl>
            </Box>
                  
            <Typography fontSize='14px' pt={2} ml={3}>
              Showing  { Math.min(invocations, tokensPerPage) }
            </Typography>
          </Box>
        </Box>
      
        <TokenList
          projectId={id}
          first={tokensPerPage}
          skip={currentPage*tokensPerPage}
          orderDirection={orderDirection}
          aspectRatio={parseAspectRatio(scriptJSON)}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Stack mt={6} mb={8} spacing={2}>
            <Pagination
              count={Math.ceil(invocations/tokensPerPage)}
              color='secondary'
              page={currentPage + 1}
              onChange={(event, page) => {
                window.scrollTo(0, 0);
                setCurrentPage(page - 1);
              }}
            />
          </Stack>
        </Box>

      </Box>
    </Box>
  )
}

export default ProjectDetails;
