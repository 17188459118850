import Page from 'components/Page';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const QuestionsPage = () => {
  return (
    <Page>
      <Box sx={{ marginTop: '150px', maxWidth: '500px' }}>
        <Typography sx={{ fontWeight: 500 }} variant='h3' color='secondary'>FAQ</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>What is an NFT?</Typography>
        <Typography>NFT stands for non-fungible token. Each token represents rights to a unique digital asset or collectible such as an avatar, wearable, or piece of digital art.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>What is Generative Art?</Typography>
        <Typography>Generative art refers to art that in whole or in part has been created with the use of an autonomous system. This is generally a system that is non-human and can independently determine features of an artwork that would otherwise require decisions made directly by the artist. Generative art makes use of machine learning and blockchain-native randomness to produce striking, unpredictable art.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>How many NFTs are included in the LOVE collection?</Typography>
        <Typography>300</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>When does the collection go on sale?</Typography>
        <Typography>December 6, 2022 at 12pm EST</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>What blockchain will be used?</Typography>
        <Typography>NFTs will be minted on the Ethereum blockchain, the world’s largest and most secure smart contract blockchain. Following transition to a Proof-Of-Stake consensus mechanism this year, energy consumption on the Ethereum network has reduced by 99%, reducing its environmental impact.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>How can I pay?</Typography>
        <Typography>NFTs can be purchased in ETH, the native token of Ethereum. Connect your wallet by hitting the ‘Sign In’ button in the top right to get started.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>I don’t have a wallet. How do I get set up?</Typography>
        <Typography>We recommend using a Metamask wallet. You can refer to their <a href='https://metamask.io/faqs/' target='_blank' rel='noopener noreferrer'>website</a> for a step-by-step guide on how to get set up and fund your account with ETH. We strongly recommend doing this in advance of the drop in order to avoid any last-minute rush.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>How much is each NFT?</Typography>
        <Typography>The price will start at a maximum of 3 ETH, and descend incrementally over a 30 minute period to a final resting price of 0.3 ETH. Collectors can decide when they would like to purchase and at what price. This sale mechanic is known as a Dutch Auction, and is commonly used in NFT projects.</Typography>  
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>Why a Dutch Auction?</Typography>
        <Typography>To prevent collectors from paying high gas fees. Gas is the transaction fee that a purchaser must pay when interacting with the Ethereum blockchain. The more users that attempt to purchase an NFT at the same time, the higher the gas fee. In allowing purchasers to enter in at different price levels, this distributes transactions over the duration of the auction as opposed to all at once.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>Can I choose which piece of artwork I’m going to receive?</Typography>
        <Typography>No. Collectors will only have their artwork revealed to them at the point of minting. Each NFT will be randomly assigned a particular shot, selected from Nitto ATP Finals data, which will be used to live-generate the artwork at the point of sale.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>Are there different levels of scarcity?</Typography>
        <Typography>Yes. Included within the collection will be shots of differing rarity, including the Championship Winning Point, Match Winning Points, and Set Winning Points, among other special editions. Each NFT’s individual characteristics will be viewable in its metadata.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>Can I order a physical print of my NFT?</Typography>
        <Typography>Yes! We are working with Level Frames to provide a seamless, top-quality experience. Printing is token-gated, meaning only you will have the right to print your NFT. Head to ‘Print Your Art’ to get started.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>What if I wish to sell or trade the NFT I received?</Typography>
        <Typography>You are free to sell it or trade your NFT on any compatible marketplace, such as Sansa. 1% of all secondary sales will be donated directly to the Giving Block’s Mental Health Impact Fund.</Typography>
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>Why is a moiré pattern showing up in my NFT?</Typography>
        <Typography>At certain resolutions on certain screens, a moiré pattern will appear in the texture of the court. However, the pattern will not appear in your downloaded image file or in any prints.</Typography>        
        <Typography variant='h5' color='secondary' sx={{paddingTop: '25px', fontWeight: 500 }}>Who can I contact for support?</Typography>
        <Typography>For any enquiries or questions, please contact{' '}
          <a href='mailto:hello@andrepeat.com' target='_blank' rel='noopener noreferrer'>hello@andrepeat.com</a>
          {' or '} 
          <a href='mailto:hello@artxcode.io' target='_blank' rel='noopener noreferrer'>hello@artxcode.io</a>
        </Typography>
      </Box>
    </Page>
  )
};

export default QuestionsPage;
 