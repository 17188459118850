import { useState } from 'react';
import { BigNumber } from '@ethersproject/bignumber';
import { GenArt721CoreV2__factory } from 'contracts';
import { useWeb3React } from '@web3-react/core';
import { coreContractAddress } from 'config';

export default function useProjectPrice(
  projectId: string,
): {
  priceWei: BigNumber | undefined,
  loading: boolean,
  error: boolean,
} {
  const { account, provider } = useWeb3React();
  const signer = provider!.getSigner(account);
  const address = coreContractAddress ? coreContractAddress : ""
  const abCoreContract = GenArt721CoreV2__factory.connect(address, signer);
  const [priceWei, setPriceWei] = useState<BigNumber | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const fetchPrice = async () => {
    try {
      const p = await abCoreContract.projectIdToPricePerTokenInWei(BigNumber.from(projectId));
      setPriceWei(p);
    } catch(error) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }
  
  useState(() => {
    fetchPrice();
  });

  return {
    priceWei,
    loading,
    error
  };
}
